import React from "react";
import _ from "lodash";
import htmlToReact from "../utils/htmlToReact";

import HeroTitle from "../components/HeroTitle";

import { Layout } from "../components/index";

export default class Success extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <section className="page">
          <div className="p-strip">
            <HeroTitle {...this.props} />
            <p>{htmlToReact(_.get(this.props, "pageContext.html"))}</p>
          </div>
        </section>
      </Layout>
    );
  }
}
